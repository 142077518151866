<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">合同中心</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">协议文件下载</a>
                </span>
            </div>
            <!-- 条件查询区域和列表区域 -->
            <div class="framePage-body">
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <!-- 搜索区域 -->
                    <div class="searchbox">
                        <div title="协议名称" class="searchboxItem ">
                            <span class="itemLabel">协议名称:</span>
                            <el-input v-model="searchForm.protocolName" placeholder="请输入协议名称" type="text" clearable size="small"/>
                        </div>
                        <div title="协议类型" class="searchboxItem">
                            <span class="itemLabel">协议类型:</span>
                            <el-select clearable placeholder="请选择协议类型" size="small" v-model="searchForm.protocolType">
                                <el-option
                                    v-for="item in protocolTypeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="签订机构" class="searchboxItem">
                            <span class="itemLabel">签订机构:</span>
                            <el-select
                                    size="small"
                                    v-model="searchForm.signCompId"
                                    remote
                                    :remote-method="getCompanyList"
                                    filterable
                                    clearable
                                    placeholder="请至少输入两个字搜索">
                                <el-option
                                    v-for="item in companyList"
                                    :key="item.compId"
                                    :label="item.compName"
                                    :value="item.compId"
                                ></el-option>
                            </el-select>
                        </div>
                         <div title="申请日期" class="searchboxItem">
                            <span class="itemLabel">申请日期:</span>
                            <el-date-picker
                                size="small"
                                v-model="searchForm.sqDate"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(true)">查询</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                min-width="180"
                                label="协议名称"
                                align="left"
                                prop="protocolName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                min-width="120"
                                label="协议类型"
                                align="left"
                                show-overflow-tooltip
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("PROTOCOL_CONTRACT_TYPE", row.protocolType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="200"
                                label="签订机构"
                                align="left"
                                prop="compName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="申请人"
                                align="left"
                                prop="createName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="审核人"
                                align="left"
                                prop="reviewer"
                                show-overflow-tooltip
                            >
                            <template slot-scope="{row}">
                                {{row.reviewer}}{{row.reviewTimeStr?`(${row.reviewTimeStr})`:''}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="审批人"
                                align="left"
                                prop="approver"
                                show-overflow-tooltip
                            >
                            <template slot-scope="{row}">
                                {{row.approver}}{{row.approveTimeStr?`(${row.approveTimeStr})`:''}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="申请日期"
                                align="left"
                                prop="createTimeStr"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="操作"
                                align="left"
                                prop="department"
                                show-overflow-tooltip
                                width="100"
                                fixed="right">
                                <template v-slot="{row}">
                                    <el-button :disabled="!row.isApprove" type="text" size="mini" style="padding: 0 5px;" @click="downloadFile(row)">下载</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    export default {
        name:"agreementToDownload",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        created(){
            // 处理协议类型字典数据
            const protocolType = this.$setDictionary("PROTOCOL_CONTRACT_TYPE", "list");
            Object.keys(protocolType).forEach((key)=>{
                this.protocolTypeOptions.push({
                    label:protocolType[key],
                    value:key
                });
            });
            this.getListData();
        },
        data(){
            return{
                // 协议列表数据
                listData:[],
                // 查询条件表单
                searchForm:{
                    protocolName:"", //协议名称
                    protocolType:"", //协议类型
                    compName:"",// 签订机构
                    sqDate:[],// 申请日期
                },
                // 分页器数据
                pagination:{
                    total: 0,
                    size: 10,
                    current: 1
                },
                // 协议类型
                protocolTypeOptions:[],
                // 签订机构数据
                companyList:[],
                // 审批状态
                approvalStatus:[]
            }
        },
        methods:{
            // 获取协议列表
            getListData(isQuery){
                const {searchForm,pagination}=this;
                if(isQuery) pagination.current=1;
                // 定义查询条件
                const condition={};
                // 合并分页器数据
                condition["pageNum"]=pagination.current;
                condition["pageSize"]=pagination.size;
                // 如果有签订日期，则进行处理
                if(searchForm.sqDate){
                    condition["startTime"]=searchForm.sqDate[0];
                    condition["endTime"]=searchForm.sqDate[1];
                }
                // 合并查询数据
                Object.keys(searchForm).forEach((key)=>{
                    if(searchForm[key]) condition[key]=searchForm[key];
                });
                this.$post("/biz/protocolContractSignFile/pageList",condition).then(res=>{
                    this.listData=res.data.list;
                    pagination.total=res.data.total;
                }).catch(e=>{
                    return e;
                });
            },
            // 分页器当前页改变
            currentChange(val){
                const {pagination}=this;
                pagination.current=val;
                this.getListData();
            },
            // 分页器条数改变
            sizeChange(val){
                const {pagination}=this;
                pagination.size=val;
                this.getListData();
            },
            // 获取签订机构数据
            getCompanyList(query) {
                if (query.trim().length >= 2) {
                    this.$post("/sys/company/queryCompanyList", { 
                        compName: query.trim()
                    }).then((res) => {
                        this.companyList = res.data || [];
                    }).catch(()=>{
                        return;
                    });
                } else {
                    this.companyList = [];
                }
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 32;
                this.tableHeight = tHeight;
            },
            // 下载
            downloadFile(row) {
                window.open(row.fileUrl);
            },
        },
        watch:{
            $route(to){
                if(to.name==="agreementApproval"){
                    this.getListData();
                }
            }
        }
    }
</script>

<style lang="less">
    .searchbox{
        .searchboxItem{
            .el-input{
                input{
                    width:100%;
                }
            }
        }
    }
    .el-message-box{
        .el-message-box__btns{
            .el-button--primary{
                background-color: #5C6BE8;
                border: none;
                &:hover{
                    background-color: #3d4fee;
                }
            }
        }
    }
    .el-table .el-table__fixed-right::before, .el-table__fixed::before{
        height: 0 !important;
    }
</style>